
// Global
import "./modules/bootstrap";
// import "./modules/theme";
// import "./modules/dragula";
import "./modules/moment";
// import "./modules/feather";
import "./modules/sidebar";
import "./modules/toastr";
// import "./modules/user-agent";

// Charts
// import "./modules/chartjs";
// import "./modules/apexcharts";

// Forms
// import "./modules/daterangepicker";
// import "./modules/datetimepicker";
// import "./modules/fullcalendar";
// import "./modules/markdown";
// import "./modules/ckeditor";
// import "./modules/mask";
// import "./modules/quill";
import "./modules/select2";
import "./modules/validation";
// import "./modules/folders";
// import "./modules/chat";
// import "./modules/wizard";

// Maps
// import "./modules/vector-maps";

// Tables
import "./modules/datatables";

// Append settings sidebar after page load
document.addEventListener("DOMContentLoaded", function () {

  // Toggle settings
  $(".toggle-settings").on("click", function (e) {
    e.preventDefault();

    $(".settings").toggleClass("open");
  });
});
