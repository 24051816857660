import toastr from "toastr/toastr";

toastr.options = {
    positionClass: 'toast-top-right',
    closeButton: 'true',
    progressBar: 'true',
    newestOnTop: 'true',
    timeOut: '3000'
}

window.toastr = toastr